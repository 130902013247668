import { logBannerWorkInProgress } from '../../utils/AnalyticsUtils.js';
import './WorkInProgress.css';

function WorkInProgress() {  
  logBannerWorkInProgress();  
    return (
        <div className='Card Container'>
          <div className="Tittle-working-on Autoresize-text">            
            <div>
              <div>
              <h1 className='Element-Left'>Working in process...</h1>
              </div>
              <div>                
                <a href='mailto:contact@luisarvizu.dev'>
                <h2 className='Element-Left'>contact@luisarvizu.dev</h2>
                </a>                                        
              </div>   
            </div>           
          </div>
          <div className="Full-screen-working-on-bg Element-Right"></div>
        </div>
      );
}

export default WorkInProgress;