import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import WorkInProgress from './components/banners/WorkInProgress';
import RandomizePrivacy from './components/Randomize/RandomizePrivacy';
import PageNotFound from './components/PageNotFound/PageNotFound';
import DevToolsPrivacy from './components/aDevTools/DevToolsPrivacy';
import { initializeFirebaseAnalytics } from './utils/AnalyticsUtils.js';

function App() {    
  initializeFirebaseAnalytics();
  return (
    <BrowserRouter>
      <Routes>        
        <Route path='*' element={<PageNotFound />}/>
        <Route index element={<WorkInProgress />} />
        <Route path='/adevtools/privacy-policy' element={<DevToolsPrivacy />}/>
        <Route path='/randomize/privacy-policy' element={<RandomizePrivacy />}/>       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
