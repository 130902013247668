// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// import WorkInProgress from "../components/banners/WorkInProgress";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgq7k6NhzMR68X-qeVq41CaF1zmlBMQYI",
  authDomain: "personal-website-4d533.firebaseapp.com",
  projectId: "personal-website-4d533",
  storageBucket: "personal-website-4d533.appspot.com",
  messagingSenderId: "842312127509",
  appId: "1:842312127509:web:8b5b4345d9781152182a1c",
  measurementId: "G-QYXRSCP6V5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export function initializeFirebaseAnalytics() {
  analytics.app.automaticDataCollectionEnabled = true;
}

export function logBannerWorkInProgress() {
    
  logEvent(analytics, 'screen_view', {
    firebase_screen: 'WorkInProgress',
    firebase_screen_class: 'WorkInProgress.jsx'
  });
}

export function logPageNotFound() {
  logEvent(analytics, 'screen_view', {
    firebase_screen: 'PageNotFound',
    firebase_screen_class: 'PageNotFound.jsx'
  });
}

export function logRandomizePrivacy() {
  logEvent(analytics, 'screen_view', {
    firebase_screen: 'RandomizePrivacy',
    firebase_screen_class: 'RandomizePrivacy.jsx'
  });
}

export function logDevToolsPrivacy() {
  logEvent(analytics, 'screen_view', {
    firebase_screen: 'DevToolsPrivacy',
    firebase_screen_class: 'DevToolsPrivacy.jsx'
  });
}
// export default initializeFirebaseAnalytics;