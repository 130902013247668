import './PageNotFound.css'
import { logPageNotFound } from '../../utils/AnalyticsUtils';
// const analytics = getAnalytics();

function PageNotFound() {
  // logEvent(analytics, 'pageNotFound_displayed');
  logPageNotFound();
    return (
        <div className='Card Container'>
          <div className="Tittle-working-on Element-Left">            
            <h1>404 Page not found</h1>
          </div>
          <div className="Full-screen-page-not-found-bg Element-Right"></div>
        </div>
      );
}

export default PageNotFound;